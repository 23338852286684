<template>
  <div class="container-contact">
    <div class="contact-text">
      <p class="text-top">当前配置如无法满足您的需求</p>
      <span class="text-btn" @click="clickToContactUs">联系我们</span>
      <p class="text-bot">我们的专业销售经理将与您取得联系并保持沟通</p>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {useRouter} from "vue-router";
import {baiduClue} from "@/api/shop";
const router = useRouter();
const clickToContactUs = () => {
	// 百度埋点
	if(sessionStorage.getItem("bd_vid")){
		let buriedData = [
			{
				logidUrl: window.location.href,
				newType: 1,
			}
		]
		baiduClue(buriedData)
	}
	router.push({
		path: '/hard'
	})
}
</script>

<style lang="less" scoped>
.container-contact {
  width: 510px;
  height: 166px;
  background: #f2f2f2;
  margin-top: 10px;
  .contact-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    line-height: 56px;
    .text-top {
      font-size: 20px;
      font-weight: 400;
      color: #555555;
    }
    .text-btn {
      width: 190px;
      height: 54px;
      line-height: 54px;
      text-align: center;
      background: #ce1200;
      font-size: 16px;
      color: #fff;
      border-radius: 27px;
      font-weight: 400;
      cursor: pointer;
    }
    .text-btn:active {
      transform: translate3d(0, -3px, 0);
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
      border: 1px solid #ce1200;
      background: transparent;
      color: #555555;
    }
    .text-bot {
      font-size: 14px;
      font-weight: 400;
      color: #555555;
    }
  }
}
</style>
